<template>
  <section class="relative">
    <div
      class="fixed right-0 bottom-0 h-full flex flex-col justify-end items-end z-10"
    >
      <img
        class="w-auto h-96 mb-20 mb-20 md:mb-auto md:h-screen md:pt-48 transition-all duration-700 p-4 pr-0 transform translate-x-1"
        src="@/assets/images/KuengCaputoLogo.svg"
        alt="Kueng Caputo"
        :class="{ small: scrolled }"
      />
    </div>
    <nav
      class="mx-auto relative z-20 w-full max-w-screen-xl grid grid-cols-4 gap-4 items-start p-4 md:pt-8"
    >
      <div class="text-xs md:text-2xs">
        DE / <router-link to="/fr">FR</router-link>
      </div>
      <div class="text-center col-span-2">
        <div class="mb-1 md:mb-4 uppercase flex justify-center">
          <img
            class="h-4 md:h-10 max-w-none w-auto"
            src="../assets/Logo.svg"
            alt="GLOBUS Logo"
          />
        </div>
      </div>
      <div class="flex justify-end font-gill tracking-wide col-span-1">
        <a
          href="https://www.globus.ch"
          class="lowercase relative z-70 inline-block no-wrap text-xs md:text-2xs bg-transparent text-black px-2 md:px-4 py-1 border border-black"
          target="_blank"
          >zu globus.ch</a
        >
      </div>
    </nav>
    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end px-4 h-90vh md:h-auto pb-8 md:pb-0"
    >
      <div
        class="pt-24 md:pt-40 font-medium text-mintro md:text-intro col-start-1 md:col-start-2 col-end-9"
      >
        Ihre Entwürfe sollen helfen, das Handwerk am Leben zu halten. Für Globus
        kreiert das Zürcher Designerinnen-Duo Kueng Caputo nun Schaufenster
        voller Wunder.
      </div>
      <div
        class="col-start-1 col-end-12 md:col-start-1 md:col-end-7 w-80 md:w-full md:h-full pt-80 md:pt-20"
      >
        <img alt="Kooperation logo" src="../assets/Kooperation_Logo.svg" />
      </div>
    </article>
    <article
      class="mx-auto max-w-screen-xl px-0 md:px-4 grid grid-cols-12 gap-4 items-start pt-8 md:pt-28"
    >
      <div
        class="order-2 md:order-1 pl-4 md:pl-0 col-start-1 md:col-start-2 col-end-10 md:col-end-6 mb-8 md:mb-0 text-mbase md:text-base"
      >
        Besucht man Kueng Caputo in ihrem Atelier im Zürcher Kreis 9, hat man
        einiges zu gucken: Fransen bunter Glitzerfolie hängen von der Decke,
        Kartons sind bis darunter gestapelt, Prototypen aus Pappe und Regale
        gefüllt mit Ordnern. Einer davon trägt die Beschriftung «On ice», hier
        steckt drin, was noch Idee ist. Das Designerinnen-Duo arbeitet offiziell
        seit 2008 zusammen. Sarah Kueng und Lovis Caputo lernten sich vor dem
        Studium an der Zürcher Hochschule der Künste kennen, begannen
        miteinander zu entwerfen und blieben dabei. Seitdem werkeln sie virtuos
        zwischen Kunst und Design. Jede Schublade, in die sie gesteckt werden,
        ist den beiden Kreativen aber zu eng.
      </div>
      <div
        class="order-1 md:order-2 px-4 col-start-1 md:col-start-7 col-end-13 md:col-end-11 md:sticky md:top-12 pb-8 md:pb-auto flex justify-center overflow:hidden"
      >
        <div
          class="w-80 h-80 flex-grow-0 flex-shrink-0 bg-white rounded-full relative"
          :style="{ left: posX + 'px' }"
        >
          <div class="flex flex-col justify-center h-full">
            <div class="text-center leading-4 tracking-wide">
              SPECIAL<br />EDITION
            </div>
            <div class="text-center pt-6 text-large">Kueng Caputo</div>
            <div class="text-center pb-12 text-intro leading-7 font-medium">
              CARPETS
            </div>
            <div class="text-center">
              <div
                v-scroll-to="'#order'"
                class="text-small cursor-pointer inline-block bg-transparent text-center px-4 py-2 border border-black"
                target="_blank"
              >
                Pre-order now
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="order-3 col-start-1 md:col-start-2 md:col-end-7 col-end-13 w-full pt-12 md:pt-20"
      >
        <img alt="" src="../assets/img/Main-Image.jpg" />
      </div>
    </article>
    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end pt-8 md:pt-28"
    >
      <div
        class="order-2 md:order-1 col-start-3 md:col-start-2 col-end-11 md:col-end-5 flex flex-col justify-center md:w-full"
      >
        <img
          alt=""
          src="../assets/img/210111_KuengCaputo_Globus_152_sRGB.jpg"
        />
      </div>
      <div
        class="order-1 md:order-2 pl-4 md:pl-0 col-start-1 md:col-start-6 col-end-10 md:col-end-10 py-8 md:pb-0 md:py-auto text-mbase md:text-base space-y-4"
      >
        <p>
          Überraschend sind auch ihre Entwürfe. Kueng Caputo verarbeiten Stein,
          Emaille, buntes Gummigranulat, Porzellan. Sie fertigen Hocker,
          Leuchten, Schalen und Vasen, richten Lokale ein. 2019 präsentieren sie
          an der Design Miami Möbelstücke und eine Tasche fürs italienische
          Modehaus Fendi. Alle Entwürfe eint, dass die beiden Frauen sich dafür
          mit Meistern des Handwerks zusammentun oder sich selbst in Material
          und Technik einarbeiten. Bis gelingt, was sie vorher in langen
          Gesprächen entworfen haben. Im vergangenen Jahr erhielten sie dafür
          den Schweizer Grand Prix Design.
        </p>
        <p>
          Die von ihnen designten High-End-Produkte sind dabei mehr, als nur
          schön anzusehen. Spricht man mit den beiden, kommt man schnell aufs
          Thema Verantwortung. Die des Designers, aber auch jene des
          Konsumenten. Man redet über faire Bezahlung, über Preis und Wert von
          etwas. Und dann auch über ihren neuesten Streich für Globus. In den
          kommenden Wochen verwandeln die Designerinnen Schaufenster in der
          ganzen Schweiz in wahre Wunderkammern.
        </p>
      </div>
    </article>

    <article class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end">
      <div
        class="pt-14 md:pt-28 pl-4 md:pl-0 col-start-1 md:col-start-2 col-end-10 md:col-end-6 space-y-4 text-mbase md:text-base"
      >
        <p>
          <span class="uppercase"
            >Ist die Carte blanche dabei eine ungewöhnliche Ausgangslage für
            euch?
          </span>
          <br />
          Kueng Caputo: Als Designerinnen sind wir es eigentlich gewöhnt, einen
          konkreten Auftrag zu bekommen. Was uns aber an diesem besonders reizt,
          ist die Fläche des Schaufensters. Das erhält gerade einen ganz neuen
          Stellenwert. Viele Institutionen fallen im Moment weg, aber
          Schaufenster kann man immer noch betrachten. So können wir unsere
          Arbeiten einem breiteren -Publikum zugänglich machen.
        </p>
        <p>
          <span class="uppercase"
            >Bei vielen davon schwingt auch das -Thema Konsumkritik mit. Wie
            funktioniert die im Schaufenster eines Premium-Warenhauses?
          </span>
          <br />
          Mit dieser Arbeit möchten wir nichts Politisches machen. Denn wonach
          sehnen sich die Menschen jetzt? Nach einem -Moment, der sie staunen
          lässt. Nach etwas Sinnlichem. Gerade ist alles sehr
          wissenschaftsbasiert. Auch Zufälliges passiert -selten. Es gibt wenig
          Visionen. Vielleicht können wir den Menschen mit unserem Projekt die
          Augen dafür öffnen. Sie verblüffen. Das Schönste wäre, unsere Arbeit
          würde dazu inspirieren, selbst aktiv zu werden, statt nur zu
          konsumieren.
        </p>
      </div>
      <div
        class="col-start-3 md:col-start-7 col-end-11 md:col-end-10 w-full pt-8 md:pt-0"
      >
        <img
          alt=""
          src="../assets/img/210111_KuengCaputo_Globus_085_sRGB.jpg"
        />
      </div>
    </article>

    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-start pt-14 md:pt-28"
    >
      <div class="col-start-1 md:col-start-2 col-end-13 md:col-end-10 w-full">
        <img
          alt=""
          src="../assets/img/210111_KuengCaputo_Globus_190_sRGB.jpg"
        />
      </div>
    </article>

    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end pt-14 md:pt-28"
    >
      <div
        class="col-start-3 md:col-start-2 col-end-11 md:col-end-5 md:top-12 pb-8 md:pb-0"
      >
        <img
          alt=""
          src="../assets/img/210111_KuengCaputo_Globus_156_sRGB.jpg"
        />
      </div>
      <div
        class="pl-4 md:pl-0 col-start-1 md:col-start-6 col-end-10 md:col-end-10 text-mbase md:text-base space-y-4"
      >
        <p>
          <span class="uppercase"
            >Ihr selbst arbeitet von Beginn an als Team. Ist der Alleinkämpfer
            eine aussterbende Gattung?
          </span>
          <br />
          Wir glauben extrem ans Kollektiv. Der Dialog ist für uns das
          Wichtigste. Gleichzeitig hat man als Team mehr Distanz zu den
          Arbeiten. Etwa wenn -etwas nicht funktioniert. Schlussendlich ist das
          nur ein Stuhl oder ein Tisch, es geht nicht um Leben und Tod. Es
          gehört zum Alltag, dass etwas nicht funktioniert. Was nicht heisst,
          dass die Zeit, die man dafür aufgewendet hat, -verloren ist. Es ist
          kein Leerlauf. Nur wenn man nichts wagt, scheitert man auch nie.
        </p>
        <p>
          <span class="uppercase"
            >Wie sehr hinterfragt ihr eure -Arbeit? Was macht sie relevant?
          </span>
          <br />
          Ein wichtiger Punkt daran ist, auf den Wert des Handwerks aufmerksam
          zu machen. Dort geht viel Wissen verloren, es stirbt buchstäblich aus.
          In der Schweiz können wir fast nicht mehr produzieren. Meist sind es
          Senioren, die unsere Leute instruieren. Wir sind hier sehr
          unvorsichtig mit wertvollem Know-how umgegangen. Der Konsument muss
          begreifen, wie viele Schritte notwendig sind, um ein Produkt zu
          fertigen. Wie viele Menschen dahinterstehen. Denn Massenproduktion
          geht immer auf Kosten derer, die diese Sachen herstellen. Die
          Handwerker, mit denen wir arbeiten, sind Experten. Wir haben grossen
          Respekt vor ihnen. Lassen uns extrem auf sie ein und verbringen viel
          Zeit damit, sie zu beobachten. So steckt hinter jedem Material, hinter
          jeder Fertigungstechnik ein ganzes Universum. Wir müssen den Wert
          dieses Wissens wieder schätzen lernen.
        </p>
        <p>
          <span class="uppercase"
            >Und wohl auch den Wert schöner -Momente. Kommen wir zurück zum
            Projekt für Globus – was kriegen wir denn genau zu sehen?
          </span>
          <br />Für acht Locations in der ganzen Schweiz kreieren wir
          Schaufenster, die kleine Geschichten erzählen. Hinter dem, was einfach
          aussieht, steckt oft eine ausgeklügelte Technik. Vielleicht gerät man
          vor unseren Schaufenstern ins Staunen. Denn unsere Welt ist doch immer
          noch voller Wunder.
        </p>
      </div>
    </article>

    <article class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end">
      <div
        class="pt-20 md:pt-28 pl-4 md:pl-0  col-start-1 md:col-start-2 col-end-12 font-medium"
      >
        MAKING-OF
      </div>
      <div
        class="col-start-1 md:col-start-2 col-end-13 md:col-end-10 w-full h-full relative"
        style="padding-top:56.25%"
      >
        <iframe
          src="https://player.vimeo.com/video/516274489?title=0&byline=0&portrait=0"
          width="640"
          height="360"
          class="absolute block inset-0 w-full h-full"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </article>

    <article
      class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end text-mbase md:text-base"
    >
      <div
        class="pl-4 md:pl-0 col-start-1 md:col-start-2 col-end-10 md:col-end-10 pt-8 md:pt-auto pb-14"
      >
        Wie funktioniert das, wenn aus Ideen Wirklichkeit wird? Im Video erleben
        Sie Kueng Caputo bei der Arbeit. Wir folgen den Künstlerinnen dabei an
        einen ihrer liebsten Orte in Zürich. Sarah Kueng und Lovis Caputo
        verraten ausserdem, was sie am Projekt für Globus reizte und was die
        beiden zu einem so guten Team macht. Ausserdem gewähren sie uns einen
        Blick in ihr Studio, wo wir den Kreativen über die Schulter und beim
        Tüfteln zuschauen.
      </div>
    </article>

    <article class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end">
      <div
        class="col-start-1 col-end-12 md:col-start-1 md:col-end-7 w-80 md:w-full md:h-full pl-4 md:pt-20"
      >
        <img alt="Kooperation logo" src="../assets/Kooperation_Logo.svg" />
      </div>
    </article>
    <article class="mx-auto max-w-screen-xl grid grid-cols-12 gap-4 items-end">
      <div
        class="col-start-2 md:col-start-1 col-end-7 md:col-end-5 md:sticky pt-8 md:top-12"
      >
        <div class="w-80 h-80 bg-white rounded-full">
          <div class="flex flex-col justify-center h-full">
            <div class="text-center leading-4 tracking-wide">
              SPECIAL<br />EDITION
            </div>
            <div class="text-center pt-6 text-large">Kueng Caputo</div>
            <div class="text-center pb-12 text-intro leading-7 font-medium">
              CARPETS
            </div>
            <div class="text-center">
              <div
                class="text-small inline-block bg-transparent text-center px-4 py-2"
                target="_blank"
              >
                Pre-order here
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="preorder"
        class="pl-4 md:pl-0  pt-12 md:pt-20 col-start-1 md:col-start-6 col-end-10 md:col-end-10 text-mbase md:text-base space-y-4"
      >
        <span class="uppercase font-medium">Special Edition</span>
        <p>
          Die Arbeiten von Kueng Caputo finden sich in Galerien und Museen – und
          vielleicht auch bald bei Ihnen zu Hause. Ihr Daheim könnte einen
          Farbtupfer vertragen? Dann ergattern Sie einen limitierten Teppich der
          Schweizer Künstlerinnen. Und zwar einen aus Gummigranulat! Zwei Jahre
          tüftelte das Duo einst an diesem neuartigen Material, das nun auch
          beim Projekt für Globus seinen grossen Auftritt hat. Kueng Caputo
          schichten bunte Berge daraus. Nach dem Abbau der Installation werden
          sie zu farbstarken Teppichen – Recycling von seiner schönsten Seite.
          Ab August sind die Stücke lieferbar und lassen das Kunst-Happening bei
          Ihnen zu Hause weitergehen. Jedes Stück ist ein Unikat und steht für
          handwerkliche Sorgfalt und den Mut, etwas Neues auszuprobieren. Die
          drei Editionen bestehen aus je 30 nummerierten und signierten
          Teppichen.
        </p>
      </div>
    </article>

    <div id="order" class="py-20 col-start-1 col-end-12 ">
      <carousel
        @page-change="changeSlide"
        :scrollPerPage="false"
        :perPageCustom="[
          [375, 1],
          [768, 3],
        ]"
        :navigationEnabled="true"
        :navigationNextLabel="''"
        :navigationPrevLabel="''"
        :paginationEnabled="false"
      >
        <slide v-if="!isMobile" class="hidden md:block"></slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-1.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              L’onda 01<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 90cm<br />
              CHF 950
            </div>
          </div>
        </slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-2.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              L’onda 02<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 90cm<br />
              CHF 950
            </div>
          </div>
        </slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-3.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              il cubo 01<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 180cm<br />
              CHF 1’200
            </div>
          </div>
        </slide>
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-5.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              il cubo 02<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 180cm<br />
              CHF 1’200
            </div>
          </div></slide
        >
        <slide>
          <div class="px-8 md:px-2 md:px-28">
            <img src="../assets/img/2021_GLOBUS-X-KUENG-CAPUTO-4.jpg" />
            <div class="pt-4 text-center text-mbase md:text-base font-medium">
              il cubo 03<br />
              Limited edition of 30<br />
            </div>
            <div class="text-center text-mbase md:text-base">
              120 x 180cm<br />
              CHF 1’200
            </div>
          </div>
        </slide>

        <slide v-if="!isMobile" class="hidden md:block"></slide>
      </carousel>
      <div class="col-start-1 col-end-3 text-center pt-10 pb-56">
        <a
          :href="
            'mailto:salut@voile.studio?subject=KUENG CAPUTO x GLOBUS, Limited Edition&body=' +
              email
          "
          class="text-small bg-transparent text-center px-6 py-2 border border-black"
          target="_blank"
          >Pre-order now</a
        >
      </div>
      <section
        class="max-w-screen-lg mx-auto px-8 md:px-4 pb-16 text-2xs leading-5 font-sans"
      >
        <div class="flex flex-wrap">
          <div class="mr-4 w-full md:w-auto">
            <span class="font-medium">HERAUSGEBERIN</span> Magazine zum Globus
            AG
          </div>
          <div class="w-full mr-4 md:w-auto">
            <span class="font-medium whitespace-no-wrap"
              >Creative Direction & Realisation
            </span>
            <a href="https://voile.studio" target="_blank">voile.studio</a>
          </div>
          <div class="w-full md:w-auto">
            <span class="font-medium whitespace-no-wrap">Copy </span>
            Leoni Hof
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="mr-4 w-full md:w-auto">
            <span class="font-medium">Photographer</span> Flavio Karrer
          </div>
          <div class="mr-4 w-full md:w-auto">
            <span class="font-medium">Videographer</span> Stefan Grah
          </div>
          <div class="w-full md:w-auto">
            <span class="font-medium">Programming</span> Frederik
            Mahler-Andersen & Hu Jung-Ting
          </div>
        </div>
        <div>@ All Rights Reserved</div>
      </section>
    </div>
  </section>
</template>

<script>
import mails from "@/emails.json";
export default {
  name: "Home",
  data() {
    return {
      slide: 0,
      scrolled: false,
      posX: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
    // console.log(mails);
  },
  computed: {
    email() {
      return mails[this.slide];
    },
    isMobile() {
      if (window.innerWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    changeSlide(data) {
      this.slide = data;
    },
    scroll() {
      if (window.scrollY > 150) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
      if (window.innerWidth > 768) {
        var start = window.innerHeight;
        var end = window.innerHeight * 2;
        if (window.scrollY > start && window.scrollY < end) {
          this.posX = (window.scrollY / start - 1) * 100;
          console.log(this.posX);
        }
      }
    },
  },
};
</script>

<style>
.VueCarousel-navigation-button {
  top: auto !important;
}
.VueCarousel-navigation-next {
  transform: translate(-100%, -50%) !important;
  bottom: 0;
  z-index: 100;
}
.VueCarousel-navigation-next:before {
  content: "";
  width: 4rem;
  height: 4rem;
  background-image: url("../assets/images/right-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.VueCarousel-navigation-prev {
  transform: translate(100%, -50%) !important;
  bottom: 0;
  z-index: 100;
}
.VueCarousel-navigation-prev:before {
  content: "";
  width: 4rem;
  height: 4rem;
  /* background: black; */
  background-image: url("../assets/images/left-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  position: absolute;
  bottom: 0;
  left: 1rem;
}
@media only screen and (min-width: 768px) {
  .VueCarousel-navigation-prev:before {
    left: 30vw;
  }
  .VueCarousel-navigation-next:before {
    right: 30vw;
  }
}
</style>
