import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/tailwind.css";
import VueCarousel from "vue-carousel";
import vueScrollTo from "vue-scroll-to";

Vue.use(vueScrollTo);

Vue.use(VueCarousel);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
